<div class="main-container">

  <div class="background-image-container"></div>

  <mat-dialog-content class="mat-typography chat-container" *ngIf="!minimized">

    <div class="dialog-header">
      <button mat-icon-button class="minimize-button" (click)="minimizeDialog()">
        <mat-icon>remove</mat-icon>
      </button>
      <button mat-icon-button class="close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="chat-messages" #chatContainer>
      <div *ngFor="let chatMessage of chatMessages" class="chat-pair">

        <div *ngIf="chatMessage.message" class="message user">{{ chatMessage.message }}</div>

        <div class="message response" *ngIf="chatMessage.response; else loadingAnimation">{{ chatMessage.response }}</div>

        <ng-template #loadingAnimation>
          <div class="dots-loading" *ngIf="chatMessage.isLoading">
            <span></span><span></span><span></span>
          </div>
        </ng-template>

        <div *ngIf="chatMessage.choices" class="choices-container">
          <button *ngFor="let choice of chatMessage.choices" mat-button (click)="selectChatType(choice)" class="choice-button">
            {{ choice }}
          </button>
        </div>

        <div *ngIf="chatMessage.categories" class="categories-container">
          <button *ngFor="let category of chatMessage.categories" mat-button (click)="selectCategory(category)" class="category-button">
            {{ category }}
          </button>
        </div>

        <div *ngIf="chatMessage.isStepper">
          <mat-vertical-stepper linear #stepper>
            <!-- Schritt für den Firmennamen -->
            <mat-step [stepControl]="companyNameFormGroup">
              <form [formGroup]="companyNameFormGroup">
                <ng-template matStepLabel>Firmennamen 🏢</ng-template>
                <mat-form-field>
                  <mat-label>Firmenname</mat-label>
                  <input matInput formControlName="companyName">
                </mat-form-field>
                <button mat-button matStepperNext>Weiter</button>
              </form>
            </mat-step>
            <!-- Erster Schritt für den Namen des Ansprechpartners -->
            <mat-step [stepControl]="nameFormGroup">
              <form [formGroup]="nameFormGroup">
                <ng-template matStepLabel>Ansprechpartner/in 👤</ng-template>
                <mat-form-field>
                  <mat-label>Ansprechpartner/in</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>
                <button mat-button matStepperNext>Weiter</button>
                <button mat-button matStepperPrevious>Zurück</button>
              </form>
            </mat-step>
            <!-- Zweiter Schritt für die Telefonnummer -->
            <mat-step [stepControl]="phoneFormGroup">
              <form [formGroup]="phoneFormGroup">
                <ng-template matStepLabel>Telefonnummer 📞</ng-template>
                <mat-form-field>
                  <mat-label>Telefonnummer</mat-label>
                  <input matInput formControlName="phone">
                </mat-form-field>
                <button mat-button matStepperNext>Weiter</button>
                <button mat-button matStepperPrevious>Zurück</button>
              </form>
            </mat-step>
            <!-- Dritter Schritt für die E-Mail -->
            <mat-step [stepControl]="emailFormGroup">
              <form [formGroup]="emailFormGroup">
                <ng-template matStepLabel>Email-Adresse 📧</ng-template>
                <mat-form-field>
                  <mat-label>Email-Adresse</mat-label>
                  <input matInput formControlName="email">
                </mat-form-field>
                <button mat-button (click)="completeStepper(stepper)" matStepperNext>Senden</button>
                <button mat-button matStepperPrevious>Zurück</button>
              </form>
            </mat-step>
          </mat-vertical-stepper>
        </div>




      </div>
    </div>

  </mat-dialog-content>

  <div class="message-input-container" *ngIf="!minimized && allowMessageInput">
    <mat-form-field appearance="fill" class="message-input custom-mat-form-field">
      <mat-label>Nachricht</mat-label>
      <input matInput [(ngModel)]="message" (keydown.enter)="sendMessage()" autofocus>
    </mat-form-field>
    <button mat-icon-button class="send-button" (click)="sendMessage()" *ngIf="message">
      <mat-icon>send</mat-icon>
    </button>
  </div>

  <div *ngIf="showTimePicker" class="time-picker-container">
    <mat-form-field appearance="fill" class="time-picker custom-mat-form-field">
      <mat-label>Gebe bitte die Dauer an!</mat-label>
      <input matInput [(ngModel)]="selectedTime" type="time" (keydown.enter)="onEnterKey()">
    </mat-form-field>
    <button mat-icon-button class="send-button" (click)="sendTime()">
      <mat-icon>send</mat-icon>
    </button>
  </div>

  <div *ngIf="showDateTimePicker" class="datetime-picker-container">
    <mat-form-field appearance="fill" class="datetime-picker custom-mat-form-field">
      <mat-label>Wähle einen Termin</mat-label>
      <input matInput [(ngModel)]="selectedDateTime" type="datetime-local" (keydown.enter)="onEnterKey()">
    </mat-form-field>
    <button mat-icon-button class="send-button" (click)="sendDateTime()">
      <mat-icon>send</mat-icon>
    </button>
  </div>

</div>
