import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ChatMessage } from '../Models/chat-message.model';
import { Url, App } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class CreateTicketService {
  private requestCategoriesUrl = Url.Api + 'LANA/CreateTicket/main-categories';
  private requestSubCategoriesUrl = Url.Api + 'LANA/CreateTicket/sub-categories';
  private requestSubCategoriesOfSubCategoryUrl = Url.Api + 'LANA/CreateTicket/sub-categories-of-sub-category';
  private requestPossibleDurationsUrl = Url.Api + 'LANA/CreateTicket/estimation-of-duration'
  private requestTechniciansListUrl = Url.Api + 'LANA/CreateTicket/technicians-list'
  private requestAppointmentsListUrl = Url.Api + 'LANA/CreateTicket/appointments-list'

  constructor(private http: HttpClient) { }

  requestCategories(): Observable<ChatMessage> {
    return this.http.get<ChatMessage>(this.requestCategoriesUrl).pipe(
      map((response: any) => {
        const categoriesWithNumbers = response.categories.map((category: string, index: number) => `${index + 1}. ${category}`);
        categoriesWithNumbers.push(`${categoriesWithNumbers.length + 1}. Individuelle Eingabe`);
        return {
          response: response.response,
          categories: categoriesWithNumbers
        };
      })
    );
  }

  requestSubCategories(sessionId: string): Observable<ChatMessage> {
    const urlWithSessionId = `${this.requestSubCategoriesUrl}?sessionId=${sessionId}`;

    return this.http.get<ChatMessage>(urlWithSessionId).pipe(
      map((response: any) => {
        const categoriesWithNumbers = response.categories.map((category: string, index: number) => `${index + 1}. ${category}`);
        categoriesWithNumbers.push(`${categoriesWithNumbers.length + 1}. Individuelle Eingabe`);
        return {
          response: response.response,
          categories: categoriesWithNumbers
        };
      })
    );
  }

  requestSubCategoriesOfSubCategory(sessionId: string): Observable<ChatMessage> {
    const urlWithSessionId = `${this.requestSubCategoriesOfSubCategoryUrl}?sessionId=${sessionId}`;

    return this.http.get<ChatMessage>(urlWithSessionId).pipe(
      map((response: any) => {
        const categoriesWithNumbers = response.categories.map((category: string, index: number) => `${index + 1}. ${category}`);
        categoriesWithNumbers.push(`${categoriesWithNumbers.length + 1}. Individuelle Eingabe`);
        return {
          response: response.response,
          categories: categoriesWithNumbers
        };
      })
    );
  }

  requestPossibleDurations(sessionId: string): Observable<ChatMessage> {
    const urlWithSessionId = `${this.requestPossibleDurationsUrl}?sessionId=${sessionId}`;

    return this.http.get<ChatMessage>(urlWithSessionId).pipe(
      map((response: any) => {
        const durations = response.durations.map((duration: string) => ` ${duration}`);
        durations.push(`Individuelle Eingabe`);
        return {
          response: response.response,
          categories: durations
        };
      })
    );
  }

  hasPreferredTechnician(): Observable<ChatMessage> {
    const response = "Hast Du einen Wunschtechniker?";
    const categories = ["Ja", "Nein"];

    return of({
      response: response,
      categories: categories
    });
  }

  requestTechniciansList(sessionId: string): Observable<ChatMessage> {
    const urlWithSessionId = `${this.requestTechniciansListUrl}?sessionId=${sessionId}`;

    return this.http.get<ChatMessage>(urlWithSessionId).pipe(
      map((response: any) => {
        const technicians = response.technicians.map((technician: string) => ` ${technician}`);
        technicians.push(`Den nächsten freien Techniker`);
        technicians.push(`Aus allen Technikern wählen`);
        return {
          response: response.response,
          categories: technicians
        };
      })
    );
  }

  requestAppointmentsList(sessionId: string): Observable<ChatMessage> {
    const urlWithSessionId = `${this.requestAppointmentsListUrl}?sessionId=${sessionId}`;

    return this.http.get<ChatMessage>(urlWithSessionId).pipe(
      map((response: any) => {
        const appointments = response.appointments.map((appointment: string) => ` ${appointment}`);
        appointments.push(`Termin angeben`);
        appointments.push('Keinen Termin passt');
        return {
          response: response.response,
          categories: appointments
        };
      })
    );
  }

  requestNoAppointmentFitsOptions(): Observable<ChatMessage> {
    // Vorbereitete statische Daten
    const responseMessage: string = "Bitte wähle eine Option!";
    const options: string[] = ['Express Ticket', 'Notfall Ticket', 'Zurück zur Terminauswahl'];

    // Erstellen des ChatMessage-Objekts
    const chatMessage: ChatMessage = {
      response: responseMessage,
      categories: options.map(option => ` ${option}`) // Optionen mit einem führenden Leerzeichen für Konsistenz
    };

    // Rückgabe als Observable
    return of(chatMessage);
  }

}
