<div class="header">
  <div class="header-left">
    <img class="logo" src="assets\images\LAN_DATA_logo.svg" />
  </div>
  <div class="header-right">
    <a href="https://www.landata.de/">{{ 'home' | translate }}</a>
    <div (click)="toggleNavCompany()">{{ 'company' | translate }}</div>
    <div (click)="toggleNavServices()">{{ 'services' | translate }}</div>
    <a href="https://landata.de/produkte/it-produkte-von-markenherstellern/alle-produkte/">{{ 'products' | translate }}</a>
    <a href="https://landata.de/produkte/it-produkte-von-markenherstellern/referenzen/">{{ 'references' | translate }}</a>
    <a class="active-link" href="#">{{ 'ticket_system' | translate }}</a>
    <a class="remote-control" href="https://landata.de/fernwartung/">{{ 'remotecontrol' | translate }}</a>
  </div>
  <div class="header-right-menu">
    <button class="burger" (click)="toggleMenu()" [ngClass]="_status ? 'is-active' : ''">
      <span class="burger-box">
        <span class="burger-inner"></span>
      </span>
    </button>
  </div>
</div>
