import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() {
    this.showNavC = new BehaviorSubject<boolean>(false);
    this.showNavS = new BehaviorSubject<boolean>(false);
  }

  private showNavC: BehaviorSubject<boolean>;
  private showNavS: BehaviorSubject<boolean>;

  setShowNavC(value) {
    this.showNavC.next(value);
  }

  setShowNavS(value) {
    this.showNavS.next(value);
  }

  public getShowNavC(): Observable<boolean> {
    return this.showNavC.asObservable();
  }

  public getShowNavS(): Observable<boolean> {
    return this.showNavS.asObservable();
  }
}
