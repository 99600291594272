
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatDialogComponent } from '../chat-dialog/chat-dialog.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {
  public uuid = "";
  private dialogRef: any = null;
  public isMinimized: boolean = false; // Zustand hinzufügen

  constructor(public dialog: MatDialog) {
    console.log('Generated UUID:', this.uuid);
  }

  toggleChat(): void {
    if (this.dialogRef) {
      const instance = this.dialogRef.componentInstance;
      if (!instance.minimized) {
        instance.minimizeDialog(); // Minimiert den Dialog
        // Warten auf die Aktualisierung des Zustands von minimized in der Dialog-Komponente
        setTimeout(() => {
          this.isMinimized = instance.minimized;
        }, 0);
      } else {
        // Wenn der Dialog bereits minimiert ist, maximiere ihn
        instance.minimizeDialog(); // Maximiert den Dialog, wenn er minimiert ist
        setTimeout(() => {
          this.isMinimized = instance.minimized;
        }, 0);
      }
    } else {

      this.uuid = uuidv4();
      this.dialogRef = this.dialog.open(ChatDialogComponent, {
        width: '850px',
        height: '700px',
        position: { bottom: '90px', right: '20px' },
        data: { message: '', uuid: this.uuid },
        hasBackdrop: false,
        disableClose: true,
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
        this.isMinimized = false; // Stellt sicher, dass isMinimized zurückgesetzt wird nach dem Schließen
      });

      // Listener für den Minimierungszustand des Dialogs
      this.dialogRef.componentInstance.minimize.subscribe((minimized: boolean) => {
        this.isMinimized = minimized;
      });
    }
  }
}
//
