import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ChatMessage } from '../Models/chat-message.model';

@Injectable({
  providedIn: 'root'
})
export class ChitChatService {

  constructor() { }

  getWelcomeMessage(): Observable<ChatMessage> {
    return of({
      response: "Willkommen im Chat-Modus, Du kannst mir Fragen stellen und ich kann sie beantworten! 🙂",
    }).pipe();
  }
}
