<app-chat></app-chat>
<app-nav></app-nav>
<div class="sub-menu" [ngClass]="_showNavC ? 'show' : ''">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <ul>
          <li>
            Wer ist LANdata
          </li>
          <li><a href="https://landata.de/unternehmen/wer-ist-landata/ueber-uns/" title="Über uns">Über uns</a></li>
          <li><a href="https://landata.de/unternehmen/wer-ist-landata/leitbild/" title="Leitbild">Leitbild</a></li>
        </ul>
      </div>
      <div class="col-lg-3">
        <ul>
          <li>
            Arbeiten bei LANdata
          </li>
          <li><a href="https://landata.de/unternehmen/arbeiten-bei-landata/karriere/" title="Karriere">Karriere</a></li>
          <li><a href="https://landata.de/unternehmen/arbeiten-bei-landata/stimmen-aus-dem-team/" title="Stimmen aus dem Team">Stimmen aus dem Team</a></li>
        </ul>
      </div>
      <div class="col-lg-3">
        <ul>
          <li>
            Support
          </li>
          <li><a href="https://landata.de/kontakt/" title="Kontakt">Kontakt</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="sub-menu" [ngClass]="_showNavS ? 'show' : ''">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <ul>
          <li>
            Besser Arbeiten
          </li>
          <li><a href="https://landata.de/leistungen/besser-arbeiten/effizienter-arbeitsplatz/" title="Effizienter Arbeitsplatz">Effizienter Arbeitsplatz</a></li>
          <li><a href="https://landata.de/leistungen/besser-arbeiten/zuverlaessige-kommunikation/" title="Zuverlässige Kommunikation">Zuverlässige Kommunikation</a></li>
          <li><a href="https://landata.de/leistungen/besser-arbeiten/it-infrastruktur/" title="IT-Infrastruktur">IT-Infrastruktur</a></li>
        </ul>
      </div>
      <div class="col-lg-3">
        <ul>
          <li>
            Neue Technologien
          </li>
          <li><a href="https://landata.de/leistungen/neue-technologien/flexible-cloudloesungen/" title="Flexible Cloudlösungen">Flexible Cloudlösungen</a></li>
          <li><a href="https://landata.de/leistungen/neue-technologien/industrie-40/" title="Industrie 4.0">Industrie 4.0</a></li>
          <li><a href="https://landata.de/leistungen/neue-technologien/softwareentwicklung/" title="Softwareentwicklung">Softwareentwicklung</a></li>
        </ul>
      </div>
      <div class="col-lg-3">
        <ul>
          <li>
            IT-Consulting
          </li>
          <li><a href="https://landata.de/leistungen/it-consulting/individuelle-beratung/" title="Individuelle Beratung">Individuelle Beratung</a></li>
          <li><a href="https://landata.de/betreuung-ihrer-it/" title="Betreuung Ihrer IT">Betreuung Ihrer IT</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<router-outlet style="height: 0px;"></router-outlet>
<!--<app-footer></app-footer>-->
