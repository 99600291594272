export enum App {
  Name = 'LANdataWTA',
  Hash = 'pwg/Aaa08dyMQZI1fVw5VQ==',
  Version = '1.2024.06.05.1',
  Language = 'de'
}

export enum Url {
  //LOCALHOST
  //Api = 'https://localhost:5001/',
  //LANdataService
  //Api = 'https://apps.landata.de/api/',
  Api = 'https://api.landata.de/api/',

  Login = '/Login',
  AfterLogin = '',
  NotFound = '404',
  NotAuthorized = '401'
}

