import { Component, Inject, OnInit, ElementRef, ViewChild, AfterViewChecked, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatService } from '../Services/chat.service';
import { ChatMessage } from '../Models/chat-message.model';
import { qnaService } from '../Services/qna.service';
import { CreateTicketService } from '../Services/create-ticket.service';
import { TicketQueryService } from '../Services/ticket-query.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChitChatService } from '../Services/chit-chat.service';

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class ChatDialogComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatContainer', { static: false }) private chatContainer?: ElementRef<HTMLDivElement>;

  uuid: string = '';
  message: string = '';
  chatMessages: ChatMessage[] = [];
  sendingMessage: boolean = false;
  chatType: string = '';
  allowMessageInput: boolean = false; // Initialzustand, um Auswahl zu erzwingen
  showTimePicker: boolean = false;
  selectedTime: string = '';
  currentStep: number = 0;
  companyNameFormGroup: FormGroup;
  nameFormGroup: FormGroup;
  phoneFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  showDateTimePicker: boolean = false;
  selectedDateTime: string = '';

  constructor(
    public dialogRef: MatDialogRef<ChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private chatService: ChatService,
    private qnaService: qnaService,
    private chitChatService: ChitChatService,
    private createTicketService: CreateTicketService,
    private ticketQueryService: TicketQueryService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('850px', '650px');
    this.chatService.getWelcomeMessage().subscribe(welcomeMessage => {
      this.chatMessages.push(welcomeMessage);
    });
    this.uuid = this.data.uuid;

    // FormGroup für den Firmennamen
    this.companyNameFormGroup = this._formBuilder.group({
      companyName: ['', Validators.required] // Validierung auf erforderliches Feld
    });

    // FormGroup für den Namen des Ansprechpartners
    this.nameFormGroup = this._formBuilder.group({
      name: ['', Validators.required] // Validierung auf erforderliches Feld
    });

    // FormGroup für die Telefonnummer
    this.phoneFormGroup = this._formBuilder.group({
      phone: ['', Validators.required] // Validierung auf erforderliches Feld
    });

    // FormGroup für die E-Mail-Adresse
    this.emailFormGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]] // Validierung auf erforderliches Feld und E-Mail-Format
    });
  
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    if (this.chatContainer) {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    }
  }

  closeDialog(): void {
    this.chatService.deleteSession(this.uuid).subscribe(() => this.dialogRef.close());
  }

  @Output() minimize = new EventEmitter<boolean>();
  minimized: boolean = false;
  minimizeDialog(): void {
    this.minimized = !this.minimized;
    this.minimize.emit(this.minimized);
    if (this.minimized) {
      this.dialogRef.updateSize('0px', '0px');
      this.dialogRef.updatePosition({ top: '0px', left: '-9999px' });
    } else {
      this.dialogRef.updateSize('850px', '650px');
      this.dialogRef.updatePosition({ bottom: '90px', right: '20px' });
    }
  }

  onEnterKey(): void {
    if (this.showTimePicker) {
      this.sendTime();
    }
    else if (this.showDateTimePicker) {
      this.sendDateTime();
    }
    else {
      this.sendMessage();
    }
  }

  sendMessage(): void {
    if (this.message.trim() !== '' && this.allowMessageInput) {
      this.allowMessageInput = false;
      this.processMessage(this.message);
    }
  }


  processMessage(text: string): void {

    const tempMessage: ChatMessage = {
      message: text,
      isLoading: true,
    };
    this.chatMessages.push(tempMessage);
    this.sendingMessage = true;

    this.chatService.sendMessage(text, this.chatType, this.uuid).subscribe(data => {
      tempMessage.response = data.response;
      tempMessage.isLoading = false;
      this.sendingMessage = false;
      // Nach dem Senden einer Nachricht erlauben wir weiterhin die Eingabe, unabhängig vom Modus
      this.allowMessageInput = true;
      //show possible durations
      if (data.step === 4) {
        this.allowMessageInput = false;
        this.requestPossibleDurations(this.uuid);
      }
      if (data.step === 5) {
        this.allowMessageInput = false;
        this.hasPreferredTechnician();
      }
      if (data.step === 9) {
        this.showDateTimePicker = true;
        this.allowMessageInput = false;
      }
      if (data.step === 10 && !this.chatMessages.some(msg => msg.isStepper)) {
        this.chatMessages.push({ isStepper: true });
        this.allowMessageInput = false;
      }
      else if (data.step===11){
        this.allowMessageInput = false;
      }
    }, error => {
      tempMessage.response = 'Übertragung fehlgeschlagen. Bitte versuche es erneut.';
      tempMessage.isLoading = false;
      this.sendingMessage = false;
      this.allowMessageInput = true;
    });

    this.message = ''; // Zurücksetzen des Eingabefeldes

  }

  selectCategory(category: string): void {
    // Direktes Senden der ausgewählten Kategorie
    const tempMessage: ChatMessage = {
      message: category,
      isLoading: true,
    };
    // Entfernen aller Nachrichten, die Kategorien enthalten, aus dem Chat
    this.chatMessages = this.chatMessages.filter(msg => !msg.categories);

    this.chatMessages.push(tempMessage); // Hinzufügen der Kategorie als gesendete Nachricht
    this.sendingMessage = true;

    this.chatService.sendMessage(category, this.chatType, this.uuid).subscribe(data => {
      tempMessage.response = data.response;
      tempMessage.isLoading = false;
      this.sendingMessage = false;

      if (data.step === 1 && data.allowInput == false) {
        this.allowMessageInput = false;
        this.requestSubCategories(this.uuid);
      }
      else if (data.step === 2 && data.allowInput == false) {
        this.allowMessageInput = false;
        this.requestSubCategoriesOfSubCategory(this.uuid);
      }
      //show time picker if possible durations dont fit
      else if (data.step === 4) {
        this.showTimePicker = true;
      }
      else if (data.step === 5) {
        this.hasPreferredTechnician();
      }
      else if (data.step === 6) {
        this.requestTechniciansList(this.uuid);
      }
      else if (data.step === 7) {
        this.requestAppointmentsList(this.uuid);
      }
      else if (data.step === 8) {
        this.noAppointmentFits(this.uuid);
      }
      else if (data.step === 9) {
        this.showDateTimePicker = true;
      }
      else if (data.step === 10 && !this.chatMessages.some(msg => msg.isStepper)) {
        this.chatMessages.push({ isStepper: true });
        this.allowMessageInput = false;
      }
      else {
        // Erlaube weiterhin die Eingabe, da der Benutzer eventuell weitere Aktionen durchführen möchte
        this.showTimePicker = false;
        this.allowMessageInput = true;
      }

    }, error => {
      // Im Fehlerfall eine Fehlermeldung setzen und die Eingabe wieder erlauben
      tempMessage.response = 'Übertragung fehlgeschlagen. Bitte versuche es erneut.';
      tempMessage.isLoading = false;
      this.sendingMessage = false;
      this.allowMessageInput = true;
    });

    // Nachdem die Kategorie ausgewählt wurde, die Eingabe wieder erlauben
    //this.allowMessageInput = true;
    // Zurücksetzen des Eingabefeldes
    this.message = '';
  }


  selectChatType(choice: string): void {
    this.chatMessages = []; // Reset der Nachrichten bei Auswahl
    if (choice === 'Chatbot') {
      this.chatType = 'ChitChat';
      this.allowMessageInput = true;
      this.chitChatService.getWelcomeMessage().subscribe(ChitChatwelcomeMessage => {
        this.chatMessages.push(ChitChatwelcomeMessage);
      });
    } else if (choice === 'Ticket-Abfrage') {
      this.chatType = 'TicketQuery';
      this.allowMessageInput = true;
      this.ticketQueryService.getWelcomeMessage().subscribe(TicketQuerywelcomeMessage => {
        this.chatMessages.push(TicketQuerywelcomeMessage);
      });
    }
    else {
      this.chatType = 'CreateTicket';
      this.getCreateTicketWelcomeMessage();
    }
  }

  completeStepper(stepper: any): void {
    if (this.companyNameFormGroup.valid && this.nameFormGroup.valid && this.phoneFormGroup.valid && this.emailFormGroup.valid) {
      const summaryMessage = `Firma: ${this.companyNameFormGroup.value.companyName}, Name: ${this.nameFormGroup.value.name}, Telefon: ${this.phoneFormGroup.value.phone}, Email: ${this.emailFormGroup.value.email}`;
      this.processMessage(summaryMessage);
      stepper.reset(); 
      this.removeStepperFromChat();
    } else {
      console.error('Formulare sind nicht vollständig oder ungültig');
    }
  }

  removeStepperFromChat(): void {
    const stepperMessageIndex = this.chatMessages.findIndex(msg => msg.isStepper);
    if (stepperMessageIndex !== -1) {
      this.chatMessages.splice(stepperMessageIndex, 1);
    }
  }

  sendTime(): void {
    if (this.selectedTime && this.selectedTime !== '--:--' && !this.selectedTime.endsWith(':')) {
      // Parsen der ausgewählten Zeit, um die Stunden und Minuten zu extrahieren
      const [hours, minutes] = this.selectedTime.split(':').map(num => parseInt(num));

      // Umrechnung der Zeit in Minuten, um die Gesamtzeit zu berechnen
      const totalMinutes = hours * 60 + minutes;

      // Prüfung, ob die Gesamtzeit 8 Stunden oder weniger beträgt
      if (totalMinutes <= 8 * 60) {
        const timeMessage = `${this.selectedTime}`;
        this.processMessage(timeMessage);
        this.selectedTime = ''; // Zurücksetzen der ausgewählten Zeit
        this.showTimePicker = false;
      }
    }
  }

  sendDateTime(): void {
    // Überprüfe, ob ein gültiges Datum ausgewählt wurde
    if (this.selectedDateTime) {
      const dateTimeMessage = `Termin: ${this.selectedDateTime}`;
      this.processMessage(dateTimeMessage);
      this.selectedDateTime = ''; // Zurücksetzen des ausgewählten Datums und der Zeit
      this.removeDateTimePickerFromChat();
      this.showDateTimePicker = false;
    }
  }

  removeDateTimePickerFromChat(): void {
    const dateTimePickerMessageIndex = this.chatMessages.findIndex(msg => msg.isDateTimePicker);
    if (dateTimePickerMessageIndex !== -1) {
      this.chatMessages.splice(dateTimePickerMessageIndex, 1);
    }
  }

  private getCreateTicketWelcomeMessage(): void {
    this.createTicketService.requestCategories().subscribe(RequestCategories => {
      this.chatMessages.push(RequestCategories);
      this.allowMessageInput = false; // Nur in diesem Fall deaktivieren wir die Eingabe
    });
  }


  requestSubCategories(sessionId: string): void {
    this.createTicketService.requestSubCategories(sessionId).subscribe(RequestSubCategories => {
      this.chatMessages.push(RequestSubCategories);
    });
  }

  requestSubCategoriesOfSubCategory(sessionId: string): void {
    this.createTicketService.requestSubCategoriesOfSubCategory(sessionId).subscribe(RequestSubCategoriesOfSubCategory => {
      this.chatMessages.push(RequestSubCategoriesOfSubCategory);
    });
  }

  requestPossibleDurations(sessionId: string): void {
    this.createTicketService.requestPossibleDurations(sessionId).subscribe(RequestPossibleDurations => {
      this.chatMessages.push(RequestPossibleDurations);
    });
  }

  hasPreferredTechnician(): void {
    this.createTicketService.hasPreferredTechnician().subscribe(HasPreferredTechnician => {
      this.chatMessages.push(HasPreferredTechnician);
    });
  }

  requestTechniciansList(sessionId: string): void {
    this.createTicketService.requestTechniciansList(sessionId).subscribe(RequestTechniciansList => {
      this.chatMessages.push(RequestTechniciansList);
    });
  }

  requestAppointmentsList(sessionId: string): void {
    this.createTicketService.requestAppointmentsList(sessionId).subscribe(RequestAppointmentsList => {
      this.chatMessages.push(RequestAppointmentsList);
    });
  }

  noAppointmentFits(sessionId: string): void {
    this.createTicketService.requestNoAppointmentFitsOptions().subscribe(RequestNoAppointmentFitsOptions => {
      this.chatMessages.push(RequestNoAppointmentFitsOptions);
    });
  }

}
