import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '../snackbar/snackbar.service';
import { Url, App } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private _http: HttpClient, private _snackbarService: SnackbarService) { }

  public getCustomer(_customer: number) {
    try {
      return this._http.get(Url.Api + 'Customers', { params: { 'appHash': App.Hash, 'customer': _customer } });
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }
}
