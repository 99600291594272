import { Component, OnInit, OnDestroy } from '@angular/core';
import { faSignOutAlt, faUsersCog, faInfo, faCaretDown, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { faMedrt } from '@fortawesome/free-brands-svg-icons';
import { NavService } from './nav.service';
import { SnackbarService } from '../snackbar/snackbar.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { App } from '../config/config';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { AppService } from '../app.service';
registerLocaleData(localeDe, 'de', localeDeExtra);

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {

  _version = App.Version;

  _status: boolean = false;

  _isWorking = false;

  _subscriptions: Subscription[];

  _showNavC: boolean;
  _showNavS: boolean;

  constructor(private _navService: NavService, private _snackbarService: SnackbarService,
    private _router: Router, private _translateService: TranslateService,
    private _app: AppService) {
    _translateService.setDefaultLang(App.Language);
    _translateService.use(App.Language);
  }

  ngOnInit() {
    try {
      this._app.getShowNavC().subscribe((value) => {
        this._showNavC = value;
      })
      this._app.getShowNavS().subscribe((value) => {
        this._showNavS = value;
      })
      this.setSubscriptions();
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  ngOnDestroy() {
    try {
      this._subscriptions.forEach(s => {
        s.unsubscribe();
      });
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  toggleNavCompany() {
    this._app.setShowNavC(!this._showNavC);
    this._app.setShowNavS(false);
  }

  toggleNavServices() {
    this._app.setShowNavS(!this._showNavS);
    this._app.setShowNavC(false);
  }

  setSubscriptions() {
    try {
      this._subscriptions = [];
      this._subscriptions[this._subscriptions.length] = this._navService.sendStateWorking.subscribe(
        (data: boolean) => {
          this._isWorking = data;
        },
        (error) => {
          this._snackbarService.openErrorSnackBar(error);
          console.log(error.status + ' ' + error.statusText);
        }
      );
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  toggleMenu() {
    this._status = !this._status;
  }

  useLanguage(language: string) {
    try {
      this._translateService.use(language);
      if (language === 'de') {
        this._navService.setLanguage('de');
      }
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }
}


