<div (click)="toggleNav()" class="wrapper">
  <div class="content">
    <div style="padding: 24px">
      <div *ngIf="_success == undefined" class="content" style="padding: 25px">
        <mat-spinner style="margin: auto;"></mat-spinner>
        <p style="padding-top: 25px">{{ 'ticket_verifying' | translate }}</p>
      </div>
      <div *ngIf="!_success && _success != undefined" class="content">
        <div class="form-block">
          <p style="color: #00C18E; font-weight: 700">{{ _error }}</p>
          <p>{{ 'help_needed' | translate }}</p>
        </div>
      </div>
      <div *ngIf="_success" class="content" style="height: auto;">
        <div class="form-inline">
          <span style="color: #00C18E; font-weight: 700">{{ 'summary_headline' | translate }}</span>
        </div>
        <div class="form-inline">
          <span>{{ 'summary_text' | translate }}</span>
        </div>
        <div class="form-block">
          <mat-list>
            <mat-list-item>
              <mat-icon mat-list-icon style="color: #00C18E">person</mat-icon>
              <div mat-line style="font-weight: 700;">{{ 'name' | translate }}</div>
              <div mat-line>{{ _ticket.name }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon style="color: #00C18E">domain</mat-icon>
              <div mat-line style="font-weight: 700;">{{ 'organization' | translate }}</div>
              <div mat-line>{{ _ticket.organization }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon style="color: #00C18E">alternate_email</mat-icon>
              <div mat-line style="font-weight: 700;">{{ 'mail' | translate }}</div>
              <div mat-line>{{ _ticket.mail }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon style="color: #00C18E">call</mat-icon>
              <div mat-line style="font-weight: 700;">{{ 'phone' | translate }}</div>
              <div mat-line>{{ _ticket.phone }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon style="color: #00C18E">build</mat-icon>
              <div mat-line style="font-weight: 700;">{{ 'issue' | translate }}</div>
              <div mat-line>{{ _ticket.issue }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon style="color: #00C18E">attachment</mat-icon>
              <div mat-line style="font-weight: 700;">{{ 'files' | translate }}</div>
              <div mat-line>{{ _ticket.files.length }}{{ 'files_attached' | translate }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon style="color: #00C18E">fast_forward</mat-icon>
              <div mat-line style="font-weight: 700;">{{ 'express' | translate }}</div>
              <div mat-line *ngIf="_ticket.emergency">{{ 'emergency' | translate }}</div>
              <div mat-line *ngIf="_ticket.express && !_ticket.emergency">{{ 'yes' | translate }}</div>
              <div mat-line *ngIf="!_ticket.express && !_ticket.emergency">{{ 'no' | translate }}</div>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </div>
</div>
