<div (click)="toggleNav()" class="wrapper">
  <div class="content">
    <mat-vertical-stepper [linear]="true" #stepper>
      <mat-step [editable]="_isEditable" [stepControl]="contact">
        <ng-template matStepLabel>{{ 'personal_stepper' | translate }}</ng-template>
        <form [formGroup]="contact">
          <div class="form-inline">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'name' | translate }}</mat-label>
              <mat-icon matPrefix>person</mat-icon>
              <input matInput placeholder="{{ 'name' | translate }}" formControlName="name" required>
              <button mat-button *ngIf="_ticket.name != undefined || _ticket.name != ''" matSuffix mat-icon-button aria-label="Clear" (click)="clear('name')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error>
                {{ 'required_name' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-inline">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'customer' | translate }}</mat-label>
              <mat-icon matPrefix>domain</mat-icon>
              <input type="number" matInput placeholder="{{ 'customer_extended' | translate }}" formControlName="customer" (focusout)="getCustomer()">
              <button mat-button *ngIf="_ticket.customer != undefined || _ticket.customer != 0" matSuffix mat-icon-button aria-label="Clear" (click)="clear('customer')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-hint>
                {{ 'customer_hint' | translate }}
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="form-inline">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'organization' | translate }}</mat-label>
              <mat-icon matPrefix>domain</mat-icon>
              <input matInput placeholder="{{ 'organization' | translate }}" formControlName="organization" [(ngModel)]="_ticket.organization">
              <button mat-button *ngIf="_ticket.organization != undefined || _ticket.organization != ''" matSuffix mat-icon-button aria-label="Clear" (click)="clear('organization')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-inline">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'mail' | translate }}</mat-label>
              <mat-icon matPrefix>alternate_email</mat-icon>
              <input type="email" name="mail" matInput placeholder="mail@example.com" formControlName="mail" required>
              <button mat-button *ngIf="_ticket.mail != undefined || _ticket.mail != ''" matSuffix mat-icon-button aria-label="Clear" (click)="clear('mail')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="contact.get('mail').invalid">
                {{ getMailError() }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-inline">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'phone' | translate }}</mat-label>
              <mat-icon matPrefix>call</mat-icon>
              <input type="tel" name="phone" matInput placeholder="{{ 'phone' | translate }}" formControlName="phone" required>
              <button mat-button *ngIf="_ticket.phone != undefined || _ticket.phone != ''" matSuffix mat-icon-button aria-label="Clear" (click)="clear('phone')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error>
                {{ 'required_phone' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-inline" style="flex-direction: row">
            <span style="color:gray">
              {{ 'required' | translate }}
            </span>
            <!--</div>
            <div class="form-inline" style="flex-direction: row-reverse">-->
            <button mat-flat-button class="suffix" matStepperNext>
              {{ 'continue' | translate }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [editable]="_isEditable" [stepControl]="issue">
        <ng-template matStepLabel>{{ 'issue_stepper' | translate }}</ng-template>
        <form [formGroup]="issue">
          <div class="form-inline">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'issue' | translate }}</mat-label>
              <textarea rows="7" matInput placeholder="{{ 'issue_placeholder' | translate }}" formControlName="issue" required></textarea>
              <mat-error>
                {{ 'required_issue' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-inline">
            {{ 'files_text' | translate }}
          </div>
          <div class="form-block">
            <button mat-flat-button (click)="openBottomSheet()"><span></span><span *ngIf=""></span>{{ 'file_attach' | translate }}</button>
            <p style="color: gray; padding: 6px;">{{ 'files_max_size' | translate }}</p>
          </div>
          <div *ngIf="_files.length > 0" class="form-block" style="display: grid">
            <span *ngFor="let file of _files">
              <span class="mat-button-base mat-stroked-button" style="padding-right: 0; margin-bottom: 5px;">
                {{ file.name }} <span style="color: gray;">{{ file.size/1048576 | number:'1.0-2' }} MB</span>
                <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeFile(file)">
                  <mat-icon>delete</mat-icon>
                </button>
              </span>
            </span>
          </div>
          <mat-divider></mat-divider>
          <div class="form-inline">
            <p>
              {{ 'express_text' | translate }}<a class="link" style="font-weight: 700" target="_blank" href="https://www.landata.de/preislistedl.pdf">{{ 'price_list' | translate }}</a>.
            </p>
          </div>
          <div class="form-block">
            <mat-checkbox formControlName="express">
              <span>{{ 'express_checkbox_1' | translate }}</span>
              <span style="font-weight: 700; color:#00C18E">{{ 'express_checkbox_2' | translate }}</span>
              <span>{{ 'express_checkbox_3' | translate }}</span>
              <span style="font-weight: 700; color:#00C18E">{{ 'express_checkbox_4' | translate }}</span>
              <span>{{ 'express_checkbox_5' | translate }}</span>
            </mat-checkbox>
          </div>

          <div class="form-block">
            <mat-checkbox formControlName="emergency">
              <span>{{ 'emergency_checkbox_1' | translate }}</span>
              <span style="font-weight: 700; color:#00C18E">{{ 'emergency_checkbox_2' | translate }}</span>
              <span>{{ 'emergency_checkbox_3' | translate }}</span>
              <span style="font-weight: 700; color:#00C18E">{{ 'emergency_checkbox_4' | translate }}</span>
              <span>{{ 'emergency_checkbox_5' | translate }}</span>
              <span style="font-weight: 700; color:#00C18E">{{ 'emergency_checkbox_6' | translate }}</span>
              <span>{{ 'emergency_checkbox_7' | translate }}</span>
              <span style="font-weight: 700; color:#00C18E">{{ 'emergency_checkbox_8' | translate }}</span>
              <span>{{ 'emergency_checkbox_9' | translate }}</span>
            </mat-checkbox>
          </div>

          <div class="form-block">
            <span style="font-weight: 700; color:#00C18E">{{ 'express_emergency_note_1' | translate }}</span>
            <span>{{ 'express_emergency_note_2' | translate }}</span>
            <span style="font-weight: 700; color:#00C18E">{{ 'express_emergency_note_3' | translate }}</span>
            <span>{{ 'express_emergency_note_4' | translate }}</span>
            <span style="font-weight: 700; color:#00C18E">{{ 'express_emergency_note_5' | translate }}</span>
            <span>{{ 'express_emergency_note_6' | translate }}</span>
          </div>

          <div class="form-inline">
            <button mat-flat-button class="prefix" matStepperPrevious>
              {{ 'back' | translate }}
            </button>
            <button mat-flat-button class="suffix" matStepperNext>
              {{ 'continue' | translate }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [editable]="_isEditable" [stepControl]="termsConditions">
        <ng-template matStepLabel>{{ 'terms_stepper' | translate }}</ng-template>
        <form [formGroup]="termsConditions">
          <div class="form-block">
            <p>
              {{ 'terms_conditions_text' | translate }} <a class="link" style="font-weight: 700" target="_blank" href="https://www.landata.de/agb">{{ 'terms_conditions_link' | translate }}</a>.
            </p>
            <p>
              {{ 'private_policy_text' | translate }} <a class="link" style="font-weight: 700" target="_blank" href="https://www.landata.de/datenschutz">{{ 'private_policy_link' | translate }}</a>.
            </p>
          </div>
          <re-captcha #captchaRef="reCaptcha" size="invisible" siteKey="6LcjSm0nAAAAADGzI6RGS0E1n5xdFhvbDeJKI_RR" (resolved)="$event && createTicket($event)" required></re-captcha>
          <div class="form-inline">
            <button mat-flat-button class="prefix" matStepperPrevious>
              {{ 'back' | translate }}
            </button>
            <div style="display: flex;">
              <mat-spinner *ngIf="_isWorking" class="custom-spinner" matPrefix [diameter]="25"></mat-spinner>
              <button mat-flat-button class="suffix custom-spinner" [disabled]="termsConditions.invalid" (click)="captchaRef.execute()">
                {{ 'ticket_send' | translate }}
              </button>
            </div>
          </div>
        </form>
      </mat-step>

      <mat-step state="conclusion">
        <ng-template matStepLabel>{{ 'conclusion_stepper' | translate }}</ng-template>
        <div class="form-inline">
          <span style="color: #00C18E; font-weight: 700">{{ 'conclusion_headline' | translate }}</span>
        </div>
        <div class="form-inline">
          <span>{{ 'conclusion_text' | translate }}</span>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
