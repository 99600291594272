import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { ChatComponent } from './chat/chat.component';
import { Component, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild(ChatComponent) chatComponent: ChatComponent;

  title = 'LANdataWTA';
  _showNavC: boolean;
  _showNavS: boolean;

  constructor(private _translate: TranslateService, private _app: AppService) {
    _translate.setDefaultLang('de');
  }

  ngOnInit() {
    this._app.getShowNavC().subscribe((value) => {
      this._showNavC = value;
    })
    this._app.getShowNavS().subscribe((value) => {
      this._showNavS = value;
    })
  }

  ngAfterViewInit(): void {
    this.chatComponent.toggleChat();
  }
}


