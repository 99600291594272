import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { NavService } from '../nav/nav.service';
import { SnackbarService } from '../snackbar/snackbar.service';
import { Ticket } from '../ticket/ticket';
import { TicketService } from '../ticket/ticket.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  _ticket: Ticket;
  _success: boolean;
  _error: string;
  _showNavC: boolean;
  _showNavS: boolean;

  constructor(private _navService: NavService,
    private _snackbarService: SnackbarService,
    private _route: ActivatedRoute,
    private _ticketService: TicketService,
    private _translateService: TranslateService,
    private _app: AppService) { }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      if (params['ActivationCode'] != undefined) {
        this.verifyTicket(params['ActivationCode']);
      }
    });
    this._app.getShowNavC().subscribe((value) => {
      this._showNavC = value;
    })
    this._app.getShowNavS().subscribe((value) => {
      this._showNavS = value;
    })
  }

  toggleNav() {
    this._app.setShowNavC(false);
    this._app.setShowNavS(false);
  }

  verifyTicket(_activationCode: string) {
    try {
      this._navService.isWorking(true);
      this._ticketService.getTicket(_activationCode).subscribe(
        (ticket: Ticket) => {
          if (ticket.id != 0) {
            this._ticket = ticket;
            this._ticketService.verifyTicket(_activationCode).subscribe(
              (ret: boolean) => {
                if (ret) {
                  this._success = true;
                  var info = '';
                  this._translateService.get('ticket_verify_successfully').subscribe((res) => { info = res; });
                  this._snackbarService.openInfoSnackBar(info, 3000, 'info');
                }
                else {
                  this._success = false;
                  this._translateService.get('ticket_verify_error').subscribe((res) => { this._error = res; });

                  //var error = '';
                  //this._translateService.get('ticket_verify_error').subscribe((res) => { error = res; });
                  //this._snackbarService.openInfoSnackBar(error, 0, 'error');
                }
                this._navService.isWorking(false);
              },
              (error) => {
                this._success = false;
                this._error = error.error;
                //this._snackbarService.openErrorSnackBar(error);
                console.log(error.status + ' ' + error.statusText);
                this._navService.isWorking(false);
              }
            );
          }
          else {

          }
        }
      )
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }
}
