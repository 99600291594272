<img class="imgBackground" src="assets/images/IMG_3364b-1.jpg" />
<div>
    <p style="font-size: 56px;">404 Not Found</p>
    <br />
    <button mat-stroked-button matTooltip="{{ 'zumLogin' | translate }}" routerLink="/Login">{{ 'zumLogin' | translate }}</button>
</div>

<!--<figure>
    <div class="sad-mac"></div>
    <figcaption>
        <span class="sr-text">Error 404: Not Found</span>
        <span class="e"></span>
        <span class="r"></span>
        <span class="r"></span>
        <span class="o"></span>
        <span class="r"></span>
        <span class="_4"></span>
        <span class="_0"></span>
        <span class="_4"></span>
        <span class="n"></span>
        <span class="o"></span>
        <span class="t"></span>
        <span class="f"></span>
        <span class="o"></span>
        <span class="u"></span>
        <span class="n"></span>
        <span class="d"></span>
    </figcaption>
</figure>-->
