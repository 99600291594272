<mat-nav-list>
  <a *ngIf="!isIOS" [routerLink]="" mat-list-item (click)="audioInput.click()">
    <mat-icon matPrefix>mic</mat-icon>
    <span mat-line style="font-weight:bold;">{{ 'audio' | translate }}</span>
    <span *ngIf="!isDesktop" mat-line>{{ 'audio_note' | translate }}</span>
    <span *ngIf="isDesktop" mat-line>{{ 'audio_note_desktop' | translate }}</span>
  </a>
  <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" #audioInput accept="audio/*" />

  <a [routerLink]="" mat-list-item (click)="videoInput.click()">
    <mat-icon matPrefix>videocam</mat-icon>
    <span mat-line style="font-weight:bold;">{{ 'video' | translate }}</span>
    <span *ngIf="!isDesktop" mat-line>{{ 'video_note' | translate }}</span>
    <span *ngIf="isDesktop" mat-line>{{ 'video_note_desktop' | translate }}</span>
  </a>
  <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" #videoInput accept="video/*" />

  <a [routerLink]="" mat-list-item (click)="imageInput.click()">
    <mat-icon matPrefix>insert_photo</mat-icon>
    <span mat-line style="font-weight:bold;">{{ 'image' | translate }}</span>
    <span *ngIf="!isDesktop" mat-line>{{ 'image_note' | translate }}</span>
    <span *ngIf="isDesktop" mat-line>{{ 'image_note_desktop' | translate }}</span>
  </a>
  <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" #imageInput accept="image/*" />
</mat-nav-list>
