import { Injectable, Output, EventEmitter } from '@angular/core'
import { GermanPaginatorNormalIntl } from '../language/german';
import { SnackbarService } from '../snackbar/snackbar.service';
import { App } from '../config/config';

const ACTIVETAB = 'activeTab';

@Injectable()
export class NavService {

  _isWorking = false;
  _language: string;

  @Output() sendStateWorking = new EventEmitter<boolean>();
  @Output() sendLanguage = new EventEmitter<string>();

  constructor(private _snackbarService: SnackbarService) {
    this._language = App.Language;
  }

  isWorking(_state: boolean) {
    try {
      if (this._isWorking !== _state) {
        this._isWorking = _state;
        this.sendStateWorking.emit(_state);
      }
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  getWorkingState() {
    try {
      return this._isWorking;
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  getLanguage() {
    try {
      return this._language;
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  getLanguagePaginatorNormal() {
    try {
      return new GermanPaginatorNormalIntl();
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  setLanguage(_language: string) {
    try {
      this._language = _language;
      this.sendLanguage.emit(_language);
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  getActiveTab() {
    try {
      if (localStorage.getItem(ACTIVETAB) === null || localStorage.getItem(ACTIVETAB) === 'undefined') return 0;
      else return JSON.parse(localStorage.getItem(ACTIVETAB));
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  setActiveTab(_index: number) {
    try {
      localStorage.setItem(ACTIVETAB, JSON.stringify(_index));
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }
}
