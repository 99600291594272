import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar';
import { Howl } from 'howler';
import { Router } from '@angular/router';

@Injectable()
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar, private _router: Router) { }

  openInfoSnackBar(_message: string, _duration: number, soundFile: string) {
    if (_duration === 0) {
      this._snackBar.open(_message, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['snackbar']
      });
    }
    else {
      this._snackBar.open(_message, 'OK', {
        duration: _duration,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['snackbar']
      });
    }
    const sound = new Howl({
      src: ['assets/sounds/' + soundFile + '.wav']
    });
    sound.play();
  }

  openErrorSnackBar(error) {
    if (error.error === null || error.error === undefined || error.error.Message === undefined) {
      // local error
      if (error.status === undefined || error.statusText === undefined) {
        this._snackBar.open(error.message + ' (' + error.stack + ')', 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['snackbar']
        });
      }
      // api error
      else {
        this._snackBar.open(error.status + ' (' + error.statusText + '): ' + error.error, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['snackbar']
        });
      }
    }
    else {
      this._snackBar.open(error.status + ' (' + error.statusText + '): ' + error.error.Message, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['snackbar']
      });
    }
    const sound = new Howl({
      src: ['assets/sounds/error.wav']
    });
    sound.play();
    if (error.status === 401) {
      this._router.navigateByUrl('/401');
    }
    else if (error.status === 404) {
      this._router.navigateByUrl('/404');
    }
  }
}
