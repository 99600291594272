export class Ticket {
  id: number;
  organization: string;
  customer: number;
  name: string;
  mail: string;
  phone: string;
  issue: string;
  express: boolean;
  emergency: boolean;
  terms: boolean;
  files: File[]

  constructor() {
    this.id = 0;
    this.organization = '';
    this.customer = 0;
    this.name = '';
    this.mail = '';
    this.phone = '';
    this.issue = '';
    this.express = false;
    this.emergency = false;
    this.terms = false;
    this.files = [];
  }
}
