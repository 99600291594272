import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { SnackbarService } from '../snackbar/snackbar.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent {

  deviceInfo = null;
  isIOS: boolean;
  isAndroid: boolean;
  isDesktop: boolean;

  files: File[];

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>, private deviceService: DeviceDetectorService, private _snackbarService: SnackbarService, private _translateService: TranslateService) {
    this.getDeviceInfo()
    this.files = [];
  }

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isIOS = this.deviceService.device.toLowerCase().includes('iphone') || this.deviceService.device.toLowerCase().includes('ipad') || this.deviceService.device.toLowerCase().includes('ipod');
    this.isAndroid = this.deviceService.device.toLowerCase().includes('android');
    this.isDesktop = this.deviceService.isDesktop();
  }

  onFileSelected(files: FileList) {
    for (var i = 0; i < files.length; i++) {
      if (files.item(i).size < 157000000) {
        this.files.push(files.item(i));
        this._bottomSheetRef.dismiss(this.files);
      }
      else {
        var error = '';
        this._translateService.get('files_max_size_exceeded').subscribe((res) => { error = res; });
        this._snackbarService.openInfoSnackBar(error, 0, 'error')
      }
    }
  }
}
