import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '../snackbar/snackbar.service';
import { Ticket } from './ticket';
import { Url, App } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private _http: HttpClient, private _snackbarService: SnackbarService) { }

  public createTicket(_ticket: Ticket, _files: File[], captchaResponse: string) {
    try {
      const formData = new FormData();
      for (const file of _files) {
        formData.append(file.name, file);
      }
      formData.append("id", _ticket.id.toString());
      formData.append("customer", _ticket.customer.toString());
      formData.append("organization", _ticket.organization);
      formData.append("name", _ticket.name);
      formData.append("mail", _ticket.mail);
      formData.append("phone", _ticket.phone);
      formData.append("issue", _ticket.issue);
      formData.append("express", _ticket.express.toString());
      formData.append("emergency", _ticket.emergency.toString());

      return this._http.post(Url.Api + 'Tickets/Create', formData, { params: { 'appHash': App.Hash, 'reCaptchResponse': captchaResponse } });
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  public getTicket(_activationCode: string) {
    try {
      return this._http.get(Url.Api + 'Tickets', { params: { 'activationCode': _activationCode, 'appHash': App.Hash } });
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  public verifyTicket(_activationCode: string) {
    try {
      return this._http.put(Url.Api + 'Tickets/Verify', 'body', { params: { 'activationCode': _activationCode, 'appHash': App.Hash } });
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }
}
