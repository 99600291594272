import { Ticket } from './ticket';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TicketService } from './ticket.service';
import { NavService } from '../nav/nav.service';
import { SnackbarService } from '../snackbar/snackbar.service';
import { MatStepper } from '@angular/material/stepper';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { TranslateService } from '@ngx-translate/core';
import * as cloneDeep from 'lodash/cloneDeep';
import { AppService } from '../app.service';
import { CustomerService } from '../customer/customer.service';
import { Customer } from '../customer/customer';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})

export class TicketComponent implements OnInit {

  @ViewChild('stepper') private stepper: MatStepper;

  contact: UntypedFormGroup;
  issue: UntypedFormGroup;
  termsConditions: UntypedFormGroup;

  _isEditable: boolean = true;
  _ticket: Ticket;
  _ticketSummary: Ticket;
  _files: File[];
  _isWorking: boolean = false;
  _showNavC: boolean;
  _showNavS: boolean;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _bottomSheet: MatBottomSheet,
    private _snackbarService: SnackbarService,
    private _ticketService: TicketService,
    private _customerService: CustomerService,
    private _app: AppService,
    private _translateService: TranslateService) {
    this._ticket = new Ticket();
    this._ticketSummary = new Ticket();
    this._files = [];
  }

  toggleNav() {
    this._app.setShowNavC(false);
    this._app.setShowNavS(false);
  }

  ngOnInit() {
    this._app.getShowNavC().subscribe((value) => {
      this._showNavC = value;
    })
    this._app.getShowNavS().subscribe((value) => {
      this._showNavS = value;
    })

    this.contact = this._formBuilder.group({
      name: ['', Validators.required],
      organization: [''],
      customer: ['', Validators.max(99999)],
      mail: new UntypedFormControl('', [Validators.required, Validators.email]),
      phone: ['', Validators.required]
    });
    this.issue = this._formBuilder.group({
      issue: ['', Validators.required],
      express: [''],
      emergency: ['']
    });
    this.termsConditions = this._formBuilder.group({
    });
    this.onChanges();
  }

  openBottomSheet(): void {
    const _bsRef = this._bottomSheet.open(BottomSheetComponent);

    _bsRef.afterDismissed().subscribe((files: File[]) => {
      for (const file of files) {
        this._files.push(file);
      }
    });
  }

  onChanges() {
    try {
      this.contact.get('name').valueChanges.subscribe(val => {
        this._ticket.name = val;
      });
      this.contact.get('customer').valueChanges.subscribe(val => {
        this._ticket.customer = val;
      });
      this.contact.get('organization').valueChanges.subscribe(val => {
        this._ticket.organization = val;
      });
      this.contact.get('mail').valueChanges.subscribe(val => {
        this._ticket.mail = val;
      });
      this.contact.get('phone').valueChanges.subscribe(val => {
        this._ticket.phone = val;
      });
      this.issue.get('issue').valueChanges.subscribe(val => {
        this._ticket.issue = val;
      });
      this.issue.get('express').valueChanges.subscribe(val => {
        if (this._ticket.emergency && val === false) {
          this.issue.get('emergency').setValue(false);
          this._ticket.emergency = false;
        }

        this._ticket.express = val;
      });
      this.issue.get('emergency').valueChanges.subscribe(val => {
        if (!this._ticket.express && val === true) {
          this.issue.get('express').setValue(true);
          this._ticket.express = true;
        }

        this._ticket.emergency = val;
      });
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  clear(_form: string) {
    try {
      if (this.contact.get(_form) != null) {
        this.contact.get(_form).setValue('');
      }
      else if (this.issue.get(_form) != null) {
        this.issue.get(_form).setValue('');
      }
      else if (this.termsConditions.get(_form) != null) {
        this.termsConditions.get(_form).setValue('');
      }

    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  isInvalid() {
    return (this.contact.invalid || this.issue.invalid || this.termsConditions.invalid);
  }

  getCustomer() {
    //if (this._ticket.customer > 0) {
    //  this._customerService.getCustomer(this._ticket.customer).subscribe(
    //    (ret: Customer) => {
    //      this._ticket.organization = ret.name;
    //    },
    //    (error) => {
    //      debugger;
    //    });
    //}
  }

  createTicket(captchaResponse: string) {
    try {
      this._isWorking = true;
      this._ticketService.createTicket(this._ticket, this._files, captchaResponse).subscribe(
        (ret: boolean) => {
          if (ret) {
            this._isEditable = false;
            this.goForward();
            var info = '';
            this._translateService.get('ticket_create_successfully').subscribe((res) => { info = res; });
            this._snackbarService.openInfoSnackBar(info, 3000, 'info');
            this._ticketSummary = cloneDeep(this._ticket);
          }
          else {
            var error = '';
            this._translateService.get('ticket_create_error').subscribe((res) => { error = res; });
            this._snackbarService.openInfoSnackBar(error, 0, 'error');
          }
          this._isWorking = false;
        },
        (error) => {
          this._snackbarService.openErrorSnackBar(error);
          console.log(error.status + ' ' + error.statusText);
          this._isWorking = false;
        }
      );
    }
    catch (error) {
      this._snackbarService.openErrorSnackBar(error);
    }
  }

  removeFile(file: File) {
    const index: number = this._files.indexOf(file);
    if (index !== -1) {
      this._files.splice(index, 1);
    }
  }

  getMailError() {
    var error = '';
    if (this.contact.get('mail').hasError('required')) {
      this._translateService.get('required_mail').subscribe((res) => { error = res; });
      return error;
    }
    this._translateService.get('invalid_mail').subscribe((res) => { error = res; });
    return this.contact.get('mail').hasError('email') ? error : '';
  }

  goBack() {
    this.stepper.previous();
  }

  goForward() {
    this.stepper.next();
  }
}
