import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of} from 'rxjs';
import { ChatMessage } from '../Models/chat-message.model';
import { ChatComponent } from '../chat/chat.component';
import { Url, App } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private CreateTicketApiUrl = Url.Api + 'LANA/CreateTicket';
  private QnAApiUrl = Url.Api + 'QnA';
  private ChitChatApiUrl = Url.Api + 'LANA/ChitChat/';
  private TicketQueryUrl = Url.Api + 'LANA/TicketQuery/';
  private minimizeChatSubject = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  sendMessage(message: string, chatType: string, sessionId: string): Observable<any> {
    let apiUrl: string;
    if (chatType === 'ChitChat') {
      apiUrl = this.ChitChatApiUrl;
    } else if (chatType === 'TicketQuery') {
      apiUrl = this.TicketQueryUrl;
    } else {
      apiUrl = this.CreateTicketApiUrl;
    }
    return this.http.post(apiUrl, { text: message, sessionId: sessionId }).pipe();
  }


  deleteSession(sessionId: string): Observable<any> {
    const deleteSessionUrl = Url.Api + `LANA/CreateTicket/${sessionId}`;
    return this.http.delete(deleteSessionUrl);
  }

  notifyMinimizeChange(isMinimized: boolean): void {
    this.minimizeChatSubject.next(isMinimized);
  }

  onMinimizeChange(): Observable<boolean> {
    return this.minimizeChatSubject.asObservable();
  }

  getWelcomeMessage(): Observable<ChatMessage> {
    return of({
      response: "Hallo, Ich bin Lana, Deine KI-Assistentin.  Bitte wähle ein Modus um fortzufahren 🙂",
      choices: ["Chatbot", "Ticket erstellen", "Ticket-Abfrage"]
    }).pipe();
  }

}
